import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime.js";
import timezone from "dayjs/plugin/timezone.js";
import utc from "dayjs/plugin/utc.js";
import localizedFormat from "dayjs/plugin/localizedFormat.js";
import duration from "dayjs/plugin/duration.js";
import isToday from "dayjs/plugin/isToday.js";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);
dayjs.extend(duration);
dayjs.extend(isToday);

export function formatDateDifference(startDate: Date, endDate?: Date) {
  const start = dayjs(startDate);
  const end = endDate ? dayjs(endDate) : dayjs();
  const diff = dayjs.duration(end.diff(start));

  if (diff.asYears() >= 1) {
    const years = Math.floor(diff.asYears());
    const months = diff.subtract(years, "year").months();
    return `${years} yr${years > 1 ? "s" : ""} ${months ? `${months} mo${months > 1 ? "s" : ""}` : ""}`.trim();
  } else if (diff.asMonths() >= 1) {
    const months = Math.floor(diff.asMonths());
    return `${months} mo${months > 1 ? "s" : ""}`;
  } else if (diff.asDays() >= 1) {
    const days = Math.floor(diff.asDays());
    return `${days} day${days > 1 ? "s" : ""}`;
  } else {
    const hours = Math.floor(diff.asHours());
    return `${hours} hr${hours > 1 ? "s" : ""}`;
  }
}

export function formatBlogArticleDate (date: string) {
  return dayjs(date).format("MMMM D, YYYY");
}

export function formatChatMessageTime(timestamp: string) {
  try {
    const date = dayjs(timestamp);
    if (!date.isValid()) return "";

    if (date.isToday()) {
      return date.format("h:mm A"); // e.g. "9:31 AM"
    }

    // If it's within the last 7 days, show the day name and time
    const daysAgo = dayjs().diff(date, "day");
    if (daysAgo < 7) {
      return date.format("ddd h:mm A"); // e.g. "Mon 2:45 PM"
    }

    // For older messages, show date and time
    return date.format("D/M HH:mm"); // e.g. "15/3 14:26"
  } catch (e) {
    return "";
  }
}

export default dayjs;
